<template>
  <v-card class="greeting-card">
    <v-row class="ma-0 pa-0">
      <v-col cols="8">
        <v-card-title class="text-no-wrap pt-1 ps-2">
          欢迎使用资产管理系统! 🥳
        </v-card-title>
        <v-card-text class="d-flex align-center mt-2 pb-2 ps-2">
          <div>
          </div>
        </v-card-text>
      </v-col>

      <v-col cols="4">
        <v-img
          contain
          height="180"
          width="159"
          :src="require(`@/assets/images/misc/triangle-${$vuetify.theme.dark ? 'dark':'light'}.png`)"
          class="greeting-card-bg"
        ></v-img>
        <v-img
          contain
          height="108"
          max-width="83"
          class="greeting-card-trophy"
          src="@/assets/images/misc/trophy.png"
        ></v-img>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api/dist/vue-composition-api'
import storeModule from './statisticsStoreModule'

export default {
  props: {
  },
  setup() {
    const WORK_STORE_MODULE_NAME = 'statistics-list'

    // Register module
    if (!store.hasModule(WORK_STORE_MODULE_NAME)) store.registerModule(WORK_STORE_MODULE_NAME, storeModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(WORK_STORE_MODULE_NAME)) store.unregisterModule(WORK_STORE_MODULE_NAME)
    })
    const totalNum = ref('')

    const showTotal = () => {
      store.dispatch('statistics-list/fetchTotalLists')
        .then(response => {
          const { data } = response.data
          totalNum.value = data.downTotal
        })
        .catch(error => {
          console.log(error)
        })
    }

    return {
      showTotal,
      totalNum,
    }
  },
}
</script>

<style lang="scss" scoped>
.greeting-card {
  position: relative;
  height: 100%;
  .greeting-card-bg {
    position: absolute;
    bottom: 0;
    right: 0;
  }
  .greeting-card-trophy {
    position: absolute;
    bottom: 10%;
    right: 8%;
  }
}
// rtl
.v-application {
  &.v-application--is-rtl {
    .greeting-card-bg {
      right: initial;
      left: 0;
      transform: rotateY(180deg);
    }
    .greeting-card-trophy {
      left: 8%;
      right: initial;
    }
  }
}
</style>
